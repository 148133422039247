import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import DrawerNav from '../Navigation/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Delete, Edit, RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import InfiniteScroll from 'react-infinite-scroll-component'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const RecentActivity = () => {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const topicName = searchParams.get('topic')
    const sectionName = searchParams.get('section')
    const subtopicName = searchParams.get('subtopic')
    let navigate = useNavigate()

    const fetchData = async () => {
        setIsLoading(true)
        setError(null)

        try {
            const response = await fetch(`${BASE_URL}/api/admin/questions/v2?page=${page}&topic=${topicName}&subtopic=${subtopicName}&section=${sectionName}`)
            const data = await response.json()
            console.log('Data : ', data)
            const realData = data.data.questionsList

            if (realData.length === 0) {
                setHasMore(false)
            } else {
                setItems((prevItems) => [...prevItems, ...realData])
                console.log('Incrementing page number from - ', page)
                setPage((prevPage) => prevPage + 1)
            }
        } catch (error) {
            setError(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleEditQuestion = (id) => {
        navigate('/edit-question?id=' + id)
    }

    const handleDeleteQuestion = (id, questionText) => {
        navigate(`/delete-question?id=${id}&question=${questionText}`)
    }

    const updateHiddenStatus = async (id, hidden) => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/question-hide/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    hidden
                })
            })

            const data = await response.json()

            if (response.ok) {
                console.log(data)
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleHideQuestion = (id, status) => {
        updateHiddenStatus(id, status)
        let newItems = []
        items.forEach((item) => {
            if (item._id === id) {
                item.hidden = status
                newItems.push(item)
            } else {
                newItems.push(item)
            }
        })
        setItems(newItems)
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                    <h1 className='text-4xl font-bold my-8 text-gray-700 text-center'>
                        {' '}
                        {subtopicName} Questions in {topicName}
                    </h1>
                    <div className='flex justify-center items-center'>
                        <InfiniteScroll style={{ textAlign: 'center' }} dataLength={items.length} next={fetchData} hasMore={hasMore} loader={<h4>Loading...</h4>} endMessage={<p style={{ textAlign: 'center', marginTop: '50px' }}>No more questions to load</p>}>
                            {items.map((data, index) => (
                                <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                    <StyledTableCell align='left'>{data.question}</StyledTableCell>
                                    <StyledTableCell align='left'>{showTimeInAgoFormat(data.updatedAt)}</StyledTableCell>
                                    <StyledTableCell align='left' onClick={() => handleEditQuestion(data._id)}>
                                        <Edit sx={{ cursor: 'pointer' }} />
                                    </StyledTableCell>
                                    {!data.hidden && (
                                        <StyledTableCell align='left' onClick={() => handleHideQuestion(data._id, true)}>
                                            <RemoveRedEye sx={{ cursor: 'pointer' }} />
                                        </StyledTableCell>
                                    )}
                                    {data.hidden && (
                                        <StyledTableCell align='left' onClick={() => handleHideQuestion(data._id, false)}>
                                            <VisibilityOff sx={{ cursor: 'pointer' }} />
                                        </StyledTableCell>
                                    )}

                                    <StyledTableCell align='left' onClick={() => handleDeleteQuestion(data._id, data.question)}>
                                        <Delete sx={{ cursor: 'pointer' }} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </InfiniteScroll>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default RecentActivity
