import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../Api/constants';
import { Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Modal } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function CourseTopicsManagement() {
    const navigate = useNavigate();
    const { id } = useParams(); // Get courseId from URL parameters
    const [topicsList, setTopicsList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTopics, setFilteredTopics] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [currentTopic, setCurrentTopic] = useState(null);
    const [newTopic, setNewTopic] = useState({
        name: '',
        description: '',
        imageURL: ''
    });

    useEffect(() => {
        fetchCourseTopics();
    }, []);

    const fetchCourseTopics = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/course-topic?id=${id}&showHidden=true`);
            if (Array.isArray(response.data.data.list)) {
                setTopicsList(response.data.data.list);
                toast.success('Course topics fetched from database', {
                    position: 'bottom-left'
                });
            } else {
                console.error('Expected an array but got:', response.data.data.list);
            }
        } catch (error) {
            console.error('Error fetching course topics:', error);
            toast.error('Failed to fetch course topics', {
                position: 'bottom-left'
            });
        }
    };

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(topicsList);
        } else {
            setFilteredTopics(
                topicsList.filter(
                    (topic) =>
                        topic.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
        }
    }, [searchQuery, topicsList]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNewTopic({ name: '', description: '', imageURL: '' });
    };

    const handleOpenEditModal = (topic) => {
        setCurrentTopic(topic);
        setNewTopic({ name: topic.name, description: topic.description, imageURL: topic.imageURL });
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setCurrentTopic(null);
        setNewTopic({ name: '', description: '', imageURL: '' });
    };

    const handleOpenDeleteModal = (topic) => {
        setCurrentTopic(topic);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setCurrentTopic(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTopic((prev) => ({ ...prev, [name]: value }));
    };

    const handleCreateTopic = async () => {
        try {
            const dataToSend = { ...newTopic, courseId: id }; // Add courseId to the data being sent
            await axios.post(`${BASE_URL}/api/v1/course-topic`, dataToSend);
            toast.success('Course topic created successfully', {
                position: 'bottom-left'
            });
            handleCloseModal();
            fetchCourseTopics();
        } catch (error) {
            console.error('Error creating course topic:', error);
            toast.error('Failed to create course topic', {
                position: 'bottom-left'
            });
        }
    };

    const handleEditTopic = async () => {
        try {
            await axios.put(`${BASE_URL}/api/v1/course-topic/${currentTopic._id}`, newTopic);
            toast.success('Course topic updated successfully', {
                position: 'bottom-left'
            });
            handleCloseEditModal();
            fetchCourseTopics();
        } catch (error) {
            console.error('Error updating course topic:', error);
            toast.error('Failed to update course topic', {
                position: 'bottom-left'
            });
        }
    };

    const handleDeleteTopic = async () => {
        try {
            await axios.delete(`${BASE_URL}/api/v1/course-topic/${currentTopic._id}`);
            toast.success('Course topic deleted successfully', {
                position: 'bottom-left'
            });
            handleCloseDeleteModal();
            fetchCourseTopics();
        } catch (error) {
            console.error('Error deleting course topic:', error);
            toast.error('Failed to delete course topic', {
                position: 'bottom-left'
            });
        }
    };

    const toggleHiddenStatus = async (topic) => {
        try {
            await axios.put(`${BASE_URL}/api/v1/hide-course-topic/${topic._id}`, {
                hidden: !topic.hidden
            });
            setTopicsList((prevTopicsList) =>
                prevTopicsList.map((item) =>
                    item._id === topic._id ? { ...item, hidden: !topic.hidden } : item
                )
            );
            toast.success(`Topic ${topic.hidden ? 'shown' : 'hidden'} successfully`, {
                position: 'bottom-left'
            });
        } catch (error) {
            console.error('Error updating topic hidden status:', error);
            toast.error('Failed to update topic hidden status', {
                position: 'bottom-left'
            });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Course Topics
                    </Typography>
                    <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                        <TextField
                            size='small'
                            variant='outlined'
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ backgroundColor: 'white', width: '300px' }}
                        />
                        <div>
                            <Button variant='outlined' sx={{ mr: 4 }} color='primary' onClick={() => navigate(`/reorder-course-topic/${id}`)}>
                                Reorder Topics
                            </Button>
                            <Button variant='contained' color='success' onClick={handleOpenModal}>
                                Create Topic
                            </Button>
                        </div>
                    </Box>
                    <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#262726' }}>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>S.No</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Image URL</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Hidden</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Edit</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTopics.length > 0 ? (
                                    filteredTopics.map((topic, index) => (
                                        <TableRow key={topic._id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell
                                                sx={{ textDecoration: 'underline', cursor: 'pointer', color: '#1e88e5' }}
                                                onClick={() => navigate(`/course-topic/${topic._id}?name=${topic.name}`)}
                                            >
                                                {topic.name}
                                            </TableCell>
                                            <TableCell>{topic.description}</TableCell>
                                            <TableCell>{topic.imageURL}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => toggleHiddenStatus(topic)}>
                                                    {topic.hidden ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='contained' color='primary' onClick={() => handleOpenEditModal(topic)}>
                                                    Edit
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='contained' color='error' onClick={() => handleOpenDeleteModal(topic)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan='7' align='center'>
                                            No topics found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>

            {/* Modal for Creating a New Topic */}
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Create New Topic
                    </Typography>
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={newTopic.name}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        name="description"
                        value={newTopic.description}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Image URL"
                        variant="outlined"
                        name="imageURL"
                        value={newTopic.imageURL}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Button variant="contained" color="primary" onClick={handleCreateTopic}>
                        Create Topic
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Box>
            </Modal>

            {/* Modal for Editing a Topic */}
            <Modal
                open={isEditModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Edit Topic
                    </Typography>
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={newTopic.name}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        name="description"
                        value={newTopic.description}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Image URL"
                        variant="outlined"
                        name="imageURL"
                        value={newTopic.imageURL}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Button variant="contained" color="primary" onClick={handleEditTopic}>
                        Update Topic
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseEditModal}>
                        Cancel
                    </Button>
                </Box>
            </Modal>

            {/* Modal for Confirming Delete */}
            <Modal
                open={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Confirm Delete
                    </Typography>
                    <Typography variant="body1">
                        Are you sure you want to delete this topic?
                    </Typography>
                    <Button variant="contained" color="error" onClick={handleDeleteTopic}>
                        Delete
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                </Box>
            </Modal>

            <ToastContainer />
        </>
    );
}

export default CourseTopicsManagement;
