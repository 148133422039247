import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/constants';

export default function EditExam() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const onboardingId = queryParams.get('id');

    // const [title, setTitle] = useState('');
    const [name, setName] = useState('')
    const [description, setDescription] = useState('');

    useEffect(() => {
        const fetchOnboardingData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/exam/${onboardingId}`);
                if (response.ok) {
                    const data = await response.json();
                    const { name, description } = data.data.examData;
                    setName(name);
                    setDescription(description);
                } else {
                    console.error('Failed to fetch exam data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchOnboardingData();
    }, [onboardingId]);

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/exam/${onboardingId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    description
                }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log(data); // Success message
            } else {
                console.error(data.message); // Error message
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant="h4" sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Edit Exam
                    </Typography>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
