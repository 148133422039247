import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/constants';
import { useNavigate } from 'react-router-dom';

export default function AddCourse() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [url, setUrl] = useState('');
    const navigate = useNavigate();

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/v1/courses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    description,
                    imageURL: url
                })
            });

            const data = await response.json();

            if (response.ok) {
                setName('');
                setDescription('');
                setUrl('');
                navigate('/courses');
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Course
                    </Typography>
                    <TextField
                        label='Course Name'
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Course Description'
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <TextField
                        label='Course Image URL'
                        value={url}
                        onChange={(event) => setUrl(event.target.value)}
                        fullWidth
                        variant='outlined'
                        margin='normal'
                    />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add Course
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
