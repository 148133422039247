import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TextField,
  TableRow,
  TableHead,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add, Delete } from "@mui/icons-material";
import { showTimeInAgoFormat } from "../../Utils/Time";
import { BASE_URL } from "../../Api/constants";
import DrawerNav from "../Navigation/DrawerNav";
import { SHOW_TOAST } from "../../Notifications/Toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "black", // Make the header black
  color: theme.palette.common.white,
  fontSize: 20,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td": {
    backgroundColor: "white", // Keep rows white
    color: "black", // Text color black
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover, // Add a hover effect on odd rows if needed
  },
}));

function UserManagement() {
  const [userList, setUserList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false); // For delete confirmation

  const getUserList = useCallback(async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const response = await fetch(
        `${BASE_URL}/api/v1/users/leaderboard`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        setUserList(data.data.usersList);
        setFilteredUsers(data.data.usersList);
      }
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setShowLoader(false);
    }
  }, []);

  useEffect(() => {
    setShowLoader(true);
    getUserList();
  }, [getUserList]);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredUsers(userList);
    } else {
      setFilteredUsers(
        userList.filter(
          (user) =>
            user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, userList]);

  const handleAddUser = async () => {
    // API call to add a new user
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: newEmail, password: newUserPassword }),
      };
      const response = await fetch(
        `${BASE_URL}/api/v2/auth/signup`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        getUserList();
        setShowAddUserModal(false);
        setNewEmail("");
        setNewUserPassword("");
        SHOW_TOAST("User added successfully");
      } else {
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleResetPassword = async () => {
    if (selectedUser && newPassword) {
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: selectedUser.email,
            newPassword,
          }),
        };

        const response = await fetch(
          `${BASE_URL}/api/v2/auth-ops/reset-password`,
          requestOptions
        );
        const data = await response.json();
        if (data.status === 200) {
          SHOW_TOAST("Password reset successfully");
          setNewPassword("");
        } else {
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    setShowPasswordResetModal(false);
  };

  const handleOpenPasswordResetModal = (user) => {
    setSelectedUser(user);
    setShowPasswordResetModal(true);
  };

  const handleAdminStatusToggle = async (user) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          uid: user.uid,
          isAdmin: !user.isAdmin,
        }),
      };

      const response = await fetch(
        `${BASE_URL}/api/v1/auth-ops/admin-status`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        SHOW_TOAST(`Admin status updated to ${!user.isAdmin}`);
        getUserList();
      } else {
        SHOW_TOAST("Failed to update admin status");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };

      const response = await fetch(
        `${BASE_URL}/api/v1/auth/users/${selectedUser.uid}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === 200) {
        SHOW_TOAST("User deleted successfully");
        getUserList();
      } else {
        SHOW_TOAST("Failed to delete user");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
    setShowDeleteDialog(false); // Close the delete confirmation dialog
  };

  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user);
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DrawerNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="pb-48">
            <div className="flex justify-between items-center p-4">
              <h1 className="text-4xl font-bold">User Management</h1>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ height: "40px", backgroundColor: "white" }}
              />
            </div>
            <Table
              sx={{ width: "100%", marginBottom: 30, marginTop: 5 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">S.No</StyledTableCell>
                  <StyledTableCell align="left">Username</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Created</StyledTableCell>
                  {/* <StyledTableCell align='left'>Last Active</StyledTableCell> */}
                  {/* <StyledTableCell align='left'>Admin</StyledTableCell>
                  <StyledTableCell align='left'>Reset Password</StyledTableCell> */}
                  <StyledTableCell align="left">Delete User</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.length > 0 &&
                  filteredUsers.map((user, index) => (
                    <StyledTableRow key={user.uid}>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {user.username}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {user.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {showTimeInAgoFormat(user.createdAt)}
                      </StyledTableCell>
                      {/* <StyledTableCell align='left'>{showTimeInAgoFormat(user.lastActive)}</StyledTableCell> */}
                      {/* <StyledTableCell align='left'>
                        <Button
                          variant={user.isAdmin ? 'contained' : 'outlined'}
                          color={user.isAdmin ? 'primary' : 'secondary'}
                          onClick={() => handleAdminStatusToggle(user)}
                        >
                          {user.isAdmin ? 'Revoke Admin' : 'Make Admin'}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => handleOpenPasswordResetModal(user)}
                        >
                          Reset Password
                        </Button>
                      </StyledTableCell> */}
                      <StyledTableCell align="left">
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDeleteDialog(user)}
                        >
                          <Delete />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>

            {/* Floating Action Button (FAB) for adding new users */}
            <Fab
              color="primary"
              aria-label="add"
              sx={{ position: "fixed", bottom: 16, right: 16 }}
              onClick={() => setShowAddUserModal(true)}
            >
              <Add />
            </Fab>

            {/* Add User Modal */}
            <Dialog
              open={showAddUserModal}
              onClose={() => setShowAddUserModal(false)}
            >
              <DialogTitle>Add New User</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={newUserPassword}
                  onChange={(e) => setNewUserPassword(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowAddUserModal(false)}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={handleAddUser} color="primary">
                  Add User
                </Button>
              </DialogActions>
            </Dialog>

            {/* Reset Password Modal */}
            <Dialog
              open={showPasswordResetModal}
              onClose={() => setShowPasswordResetModal(false)}
            >
              <DialogTitle>Reset Password</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowPasswordResetModal(false)}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={handleResetPassword} color="primary">
                  Reset
                </Button>
              </DialogActions>
            </Dialog>

            {/* Delete User Confirmation Dialog */}
            <Dialog
              open={showDeleteDialog}
              onClose={() => setShowDeleteDialog(false)}
            >
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                Are you sure you want to delete the user "
                {selectedUser?.username}"?
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowDeleteDialog(false)}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={handleDeleteUser} color="primary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default UserManagement;
