/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Api/constants";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DrawerNav from "../Navigation/DrawerNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function CourseManagement() {
  const navigate = useNavigate();
  const [examsList, setExamsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);

  useEffect(() => {
    fetchSnippets();
  }, []);

  const fetchSnippets = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/courses?showHidden=true`
      );
      if (Array.isArray(response.data.data.list)) {
        setExamsList(response.data.data.list);
        toast.success("Quizzes fetched from database", {
          position: "bottom-left",
        });
      } else {
        console.error(
          "Expected an array but got:",
          response.data.data.snippets
        );
      }
    } catch (error) {
      console.error("Error fetching snippets:", error);
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredQuizzes(examsList);
    } else {
      setFilteredQuizzes(
        examsList.filter(
          (quiz) =>
            quiz.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (quiz.description &&
              quiz.description
                .toLowerCase()
                .includes(searchQuery.toLowerCase()))
        )
      );
    }
  }, [searchQuery, examsList]);

  const handleEdit = (quiz) => {
    // navigate(`/edit-resource`, { state: { quiz, action: 'edit', type: 'quiz' } });
  };

  const handleDelete = (quiz) => {
    // navigate(`/edit-resource`, { state: { quiz, action: 'delete', type: 'quiz' } });
  };

  const toggleHiddenStatus = async (quiz) => {
    try {
      await axios.put(`${BASE_URL}/api/v1/hide-course/${quiz._id}`, {
        hidden: !quiz.hidden,
      });
      setExamsList((prevExamsList) =>
        prevExamsList.map((item) =>
          item._id === quiz._id ? { ...item, hidden: !quiz.hidden } : item
        )
      );
      toast.success(`Quiz ${quiz.hidden ? "shown" : "hidden"} successfully`, {
        position: "bottom-left",
      });
    } catch (error) {
      console.error("Error updating quiz hidden status:", error);
      toast.error("Failed to update quiz hidden status", {
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DrawerNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography
            variant="h4"
            sx={{ my: 2, fontWeight: "bolder", color: "#4b4848" }}
          >
            Courses
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ backgroundColor: "white", width: "300px" }}
            />
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={() => navigate(`/add-course`)}
              >
                Create Course
              </Button>
            </div>
          </Box>
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#262726" }}>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    S.No
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Quiz
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Description
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Hidden
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Edit
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                    Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredQuizzes.length > 0 ? (
                  filteredQuizzes.map((quiz, index) => (
                    <TableRow
                      key={quiz._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { backgroundColor: "#f5f5f5" },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#1e88e5",
                        }}
                        onClick={() =>
                          navigate(`/course/${quiz._id}?name=${quiz.name}`)
                        }
                      >
                        {quiz.name}
                      </TableCell>
                      <TableCell>{quiz.description}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleHiddenStatus(quiz)}>
                          {quiz.hidden ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEdit(quiz)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(quiz)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="8" align="center">
                      No quizzes found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
}

export default CourseManagement;
