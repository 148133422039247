import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField, Typography, MenuItem, Select, FormControl, InputLabel, Paper, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
    Fab, Table, TableBody, TableCell, TableContainer, TableRow
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon, Reorder as ReorderIcon } from '@mui/icons-material';
import MainLayout from '../Navigation/MainLayout';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../Api/constants';
import { renderQuestionText } from '../../Utils/String';

const CourseTopicInfo = () => {
    const { id } = useParams(); // Extract the ID from the query parameters
    const [selectedContentType, setSelectedContentType] = useState('');
    const [contentList, setContentList] = useState([]);
    const [contentData, setContentData] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [examList, setExamList] = useState([]); // State for storing the list of exams
    const [selectedExam, setSelectedExam] = useState(''); // State for storing the selected exam
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for adding and editing content
    const [isReordering, setIsReordering] = useState(false); // State for reordering mode
    const [editingContent, setEditingContent] = useState(null); // State for editing content

    // Fetch the course topic content on component mount
    const fetchContent = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/course-topic-resources?id=${id}`);
            setContentList(response.data.data.list);
            setLoading(false);
        } catch (err) {
            setError('Failed to load content');
            setLoading(false);
        }
    };

    // Fetch exams when the "Exam" content type is selected
    const fetchExams = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/v1/course-quizzes`);
            setExamList(response.data.data.list);
        } catch (err) {
            setError('Failed to load exams');
        }
    };

    useEffect(() => {
        fetchContent();
    }, [id]);

    useEffect(() => {
        if (selectedContentType === 'Exam') {
            fetchExams();
        }
    }, [selectedContentType]);

    const handleContentTypeChange = (event) => {
        setSelectedContentType(event.target.value);
        setSelectedExam(''); // Reset selected exam when changing content type
    };

    const handleContentDataChange = (event) => {
        setContentData(event.target.value);
    };

    const handleExamChange = (event) => {
        setSelectedExam(event.target.value);
    };

    const handleAddContent = async () => {
        if (selectedContentType && (selectedContentType !== 'Exam' || selectedExam)) {
            const newContent = {
                courseTopicId: id, // Include the courseTopicId in the request body
                type: selectedContentType,
                content: selectedContentType === 'Exam' ? selectedExam : contentData, // Send exam _id if type is Exam
            };

            try {
                const response = await axios.post(`${BASE_URL}/api/v1/course-topic-resources`, newContent);
                if (response.status === 200) {
                    fetchContent();
                    setContentData('');
                    setSelectedExam('');
                    setIsModalOpen(false); // Close modal after adding content
                }
            } catch (err) {
                setError('Failed to add content');
            }
        }
    };

    const handleEditContent = async () => {
        if (editingContent && selectedContentType && (selectedContentType !== 'Exam' || selectedExam)) {
            const updatedContent = {
                type: selectedContentType,
                content: selectedContentType === 'Exam' ? selectedExam : contentData,
            };

            try {
                const response = await axios.put(`${BASE_URL}/api/v1/course-topic-resources/${editingContent._id}`, updatedContent);
                if (response.status === 200) {
                    fetchContent();
                    setIsModalOpen(false);
                    setEditingContent(null);
                }
            } catch (err) {
                setError('Failed to edit content');
            }
        }
    };

    const handleDeleteContent = async (contentId) => {
        try {
            const response = await axios.delete(`${BASE_URL}/api/v1/course-topic-resources/${contentId}`);
            if (response.status === 200) {
                fetchContent();
            }
        } catch (err) {
            setError('Failed to delete content');
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedList = Array.from(contentList);
        const [removed] = reorderedList.splice(result.source.index, 1);
        reorderedList.splice(result.destination.index, 0, removed);

        setContentList(reorderedList);
    };

    const handleSaveOrder = async () => {
        try {
            const reorderedContent = contentList.map((content, index) => ({
                ...content,
                order: index + 1,
            }));

            await axios.put(`${BASE_URL}/api/v1/reorder-course-topic-resources/${id}`, {
                resources: reorderedContent,
            });

            fetchContent();
            setIsReordering(false);
        } catch (err) {
            setError('Failed to save reordered content');
        }
    };

    const openModal = (content = null) => {
        if (content) {
            setEditingContent(content);
            setSelectedContentType(content.type);
            setContentData(content.type === 'Exam' ? content.quiz._id : content.content);
            setSelectedExam(content.type === 'Exam' ? content.quiz._id : '');
        } else {
            setEditingContent(null);
            setSelectedContentType('');
            setContentData('');
            setSelectedExam('');
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingContent(null);
    };

    return (
        <MainLayout>
            <Box sx={{ p: 3 }}>
                <Typography variant="h4" sx={{ mb: 4 }}>
                    Course Topic Content
                </Typography>

                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ReorderIcon />}
                    onClick={() => setIsReordering(!isReordering)}
                    sx={{ mb: 4 }}
                >
                    {isReordering ? 'Stop Reordering' : 'Reorder Content'}
                </Button>

                {isReordering && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveOrder}
                        sx={{ mb: 4, ml: 2 }}
                    >
                        Save Order
                    </Button>
                )}

                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="content">
                        {(provided) => (
                            <TableContainer
                                component={Paper}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <Table sx={{ minWidth: 650 }}>
                                    <TableBody>
                                        {contentList.map((content, index) => (
                                            <Draggable
                                                key={content._id}
                                                draggableId={content._id}
                                                index={index}
                                                isDragDisabled={!isReordering} // Disable dragging if not reordering
                                            >
                                                {(provided) => (
                                                    <TableRow
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <TableCell>{content.type}</TableCell>
                                                        <TableCell>
                                                            {content.type === 'Exam'
                                                                ? content.quiz ? content.quiz.name : ''
                                                                : content.type === 'Image'
                                                                    ? <img src={content.content} alt="Content" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                                                    : content.type === 'Video'
                                                                        ? <video className='rounded-2xl' width="500px" controls>
                                                                            <source src={content.content} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                        </video>
                                                                        : renderQuestionText(content.content)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                onClick={() => openModal(content)}
                                                                color="primary"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteContent(content._id)}
                                                                color="secondary"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Droppable>
                </DragDropContext>

                {error && (
                    <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>

            <Dialog fullScreen sx={{ p: 16}} open={isModalOpen} onClose={closeModal}>
                <DialogTitle>{editingContent ? 'Edit Content' : 'Add Content'}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="content-type-label">Content Type</InputLabel>
                        <Select
                            labelId="content-type-label"
                            value={selectedContentType}
                            onChange={handleContentTypeChange}
                            label="Content Type"
                        >
                            <MenuItem value="Introduction to the series">Introduction to the series</MenuItem>
                            <MenuItem value="Reading">Reading</MenuItem>
                            <MenuItem value="Video">Video</MenuItem>
                            <MenuItem value="Image">Image</MenuItem>
                            <MenuItem value="Exam">Exam</MenuItem>
                        </Select>
                    </FormControl>

                    {selectedContentType === 'Exam' ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="exam-select-label">Select Exam</InputLabel>
                            <Select
                                labelId="exam-select-label"
                                value={selectedExam}
                                onChange={handleExamChange}
                                label="Select Exam"
                            >
                                {examList.map((exam) => (
                                    <MenuItem key={exam._id} value={exam._id}>
                                        {exam.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            label="Content"
                            value={contentData}
                            onChange={handleContentDataChange}
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ mb: 2 }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={editingContent ? handleEditContent : handleAddContent} color="primary">
                        {editingContent ? 'Save Changes' : 'Add Content'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => openModal()}
            >
                <AddIcon />
            </Fab>
        </MainLayout>
    );
};

export default CourseTopicInfo;
