import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { BASE_URL } from '../../Api/constants';

const MediaUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedUrl, setUploadedUrl] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        console.log('Selected file:', file);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }

        setLoading(true); // Start loading

        const formData = new FormData();
        formData.append('file', selectedFile);

        // Debugging step: Log FormData content
        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        try {
            const response = await axios.post(`${BASE_URL}/api/v1/s3/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response:', response);

            setUploadedUrl(response.data.data.url);
        } catch (error) {
            console.error('Error uploading the file', error.response);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleCopyUrl = () => {
        if (uploadedUrl) {
            navigator.clipboard.writeText(uploadedUrl)
                .then(() => {
                    alert('URL copied to clipboard');
                })
                .catch((err) => {
                    console.error('Failed to copy the URL:', err);
                });
        }
    };

    const renderMediaPreview = () => {
        if (!uploadedUrl) return null;

        const fileType = selectedFile?.type;

        if (fileType?.startsWith('image/')) {
            return <img src={uploadedUrl} alt="Uploaded" className="mt-2 max-w-full h-auto rounded-3xl" />;
        } else if (fileType?.startsWith('video/')) {
            return <video src={uploadedUrl} controls className="mt-2 max-w-full h-auto rounded-3xl" />;
        } else if (fileType?.startsWith('audio/')) {
            return <audio src={uploadedUrl} controls className="mt-2 w-full rounded-3xl" />;
        } else if (fileType === 'application/pdf') {
            return (
                <div className="mt-2 max-w-full h-auto rounded-3xl">
                    <iframe src={uploadedUrl} width="100%" height="500px" title="PDF Preview" />
                </div>
            );
        }

        return null;
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <div className="w-full my-10 p-6 bg-white shadow-lg rounded-lg">
                        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                            UPLOAD MEDIA
                        </Typography>
                        {!uploadedUrl && !loading && (
                            <>
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    accept="image/*,video/*,audio/*,application/pdf"
                                    className="block w-full text-sm text-gray-500
                   file:mr-4 file:py-2 file:px-4
                   file:rounded-full file:border-0
                   file:text-sm file:font-semibold
                   file:bg-indigo-50 file:text-indigo-700
                   hover:file:bg-indigo-100
                   mb-4"
                                />
                                <button
                                    onClick={handleUpload}
                                    className="bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition duration-300"
                                >
                                    Upload
                                </button>
                            </>
                        )}

                        {loading && (
                            <div className="flex justify-center my-10">
                                <CircularProgress />
                            </div>
                        )}

                        {uploadedUrl && !loading && (
                            <div className="mt-4">
                                <button
                                    onClick={() => { window.location.reload() }}
                                    className="bg-indigo-600 text-white py-2 px-4 my-4 rounded hover:bg-indigo-700 transition duration-300"
                                >
                                    Upload More
                                </button>
                                <a href={uploadedUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 underline flex flex-wrap">
                                    {uploadedUrl}
                                </a>
                                <button
                                    onClick={handleCopyUrl}
                                    className="my-4 bg-gray-200 text-gray-700 py-1 px-3 rounded hover:bg-gray-300 transition duration-300"
                                >
                                    Copy URL
                                </button>
                                {renderMediaPreview()}
                            </div>
                        )}
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default MediaUpload;
