import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import { BASE_URL } from "../../Api/constants";
import { SHOW_TOAST } from "../../Notifications/Toast";

function AssignCoursesToUser() {
  const [userList, setUserList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [showAssignDialog, setShowAssignDialog] = useState(false);

  // Fetch users and courses on mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/users/leaderboard`);
        const data = await response.json();
        if (data.status === 200) {
          setUserList(data.data.usersList);
        }
      } catch (error) {
        console.log("Error fetching users:", error);
      }
    };

    const fetchCourses = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/quizzes`);
        const data = await response.json();
        if (data.status === 200) {
          setCourseList(data.data.list);
        }
      } catch (error) {
        console.log("Error fetching courses:", error);
      }
    };

    fetchUsers();
    fetchCourses();
  }, []);

  // Fetch assigned courses when user changes
  useEffect(() => {
    const fetchAssignedCourses = async () => {
      if (!selectedUser) return;
      try {
        const res = await fetch(
          `${BASE_URL}/api/v1/assign-courses?uid=${selectedUser}`
        );
        const data = await res.json();
        if (data.status === 200) {
          setSelectedCourses(data.data.courses);
        }
      } catch (err) {
        console.log("Error fetching assigned courses:", err);
      }
    };

    fetchAssignedCourses();
  }, [selectedUser]);

  const handleCourseToggle = (courseID) => {
    if (selectedCourses.includes(courseID)) {
      setSelectedCourses(selectedCourses.filter((id) => id !== courseID));
    } else {
      setSelectedCourses([...selectedCourses, courseID]);
    }
  };

  const handleAssignCourses = async () => {
    try {
      const requestBody = {
        uid: selectedUser,
        quizzes: selectedCourses,
      };

      const response = await fetch(`${BASE_URL}/api/v1/assign-courses`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status === 200) {
        SHOW_TOAST("Courses assigned successfully!");
        setShowAssignDialog(false);
      } else {
        SHOW_TOAST("Failed to assign courses.");
      }
    } catch (error) {
      console.log("Error assigning courses:", error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" mb={2}>
        Assign Quizzes to User
      </Typography>

      {/* User Dropdown */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Select User</InputLabel>
        <Select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          label="Select User"
        >
          {userList.map((user) => (
            <MenuItem key={user.uid} value={user.uid}>
              {user.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Course Grid */}
      <Typography variant="subtitle1" mt={3} mb={1}>
        Select Quizzes:
      </Typography>
      <Paper variant="outlined" sx={{ p: 2, overflow: "auto" }}>
        <Grid container spacing={2}>
          {courseList.map((course) => (
            <Grid item xs={6} sm={4} md={4} key={course.id}>
              <Checkbox
                checked={selectedCourses.includes(course.id)}
                onChange={() => handleCourseToggle(course.id)}
              />
              <span>{course.name}</span>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Assign Button */}
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedUser || selectedCourses.length === 0}
          onClick={() => setShowAssignDialog(true)}
        >
          Assign Quizzes
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={showAssignDialog}
        onClose={() => setShowAssignDialog(false)}
      >
        <DialogTitle>Confirm Assignment</DialogTitle>
        <DialogContent>
          Are you sure you want to assign {selectedCourses.length} quizzes to{" "}
          {selectedUser}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAssignDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAssignCourses} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AssignCoursesToUser;
