// src/navItems.js

import { MusicNote, Person } from '@mui/icons-material';
import { FaAssistiveListeningSystems, FaFeatherAlt, FaFolderMinus, FaHome, FaLayerGroup, FaQuestionCircle, FaSearch, FaSignOutAlt, FaTasks } from "react-icons/fa";

export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <FaHome size={'23px'} />
    },
    {
        value: 'Add Quiz',
        route: 'add-quiz-v2',
        icon: <FaFeatherAlt size={'23px'} />
    },
    {
        value: 'Quiz Management',
        route: 'exam-management',
        icon: <FaQuestionCircle size={'23px'} />
    },
    {
        value: 'Media Upload',
        route: 'media-upload',
        icon: <MusicNote size={'23px'} />
    },
    {
        value: 'Search Question',
        route: 'search-question',
        icon: <FaSearch size={'23px'} />
    },
    {
        value: 'Contact Submissions',
        route: 'contact-submissions',
        icon: <FaSearch size={'23px'} />
    },
    {
        value: 'Users',
        route: 'users',
        icon: <Person size={'23px'} />
    },
];

export const navItems2 = [
    {
        value: 'Add Course',
        route: 'add-course',
        icon: <FaFolderMinus size={'23px'} />
    },
    {
        value: 'Course Management',
        route: 'courses',
        icon: <FaLayerGroup size={'23px'} />
    },
    {
        value: 'Assign Courses',
        route: 'assign-courses',
        icon: <FaTasks size={'23px'} />
    }
];

export const logoutItem = {
    value: 'Logout',
    route: 'login',
    icon: <FaSignOutAlt size={'23px'} />
};
