import { Box, TableContainer, Checkbox } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../Api/constants';
import { showTimeInAgoFormat } from '../../Utils/Time';

function ContactUsForm() {
    const [contactUsData, setContactUsData] = useState([]);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        axios.get(`${BASE_URL}/api/v1/users/contact-us`)
            .then(response => {
                const list = response.data.data.list;
                setContactUsData(list);
            })
            .catch(error => {
                console.error('Error fetching contact us data:', error);
            });
    }, []);

    // Function to handle checkbox change
    const handleCheckboxChange = (contactId, currentStatus) => {
        const updatedStatus = !currentStatus; // Toggle the status

        // Call the API to update the status
        axios.put(`${BASE_URL}/api/v1/users/contact-us/${contactId}`, {
            status: updatedStatus,
        })
            .then(response => {
                // Update the contactUsData with the new status
                setContactUsData(prevData =>
                    prevData.map(contact =>
                        contact._id === contactId ? { ...contact, status: updatedStatus } : contact
                    )
                );
            })
            .catch(error => {
                console.error('Error updating contact status:', error);
            });
    };

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Submitted At</TableCell>
                                    <TableCell>Reviewed</TableCell> {/* New Checkbox Column */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contactUsData.map((contact) => (
                                    <TableRow key={contact._id}>
                                        <TableCell>{contact.name}</TableCell>
                                        <TableCell>{contact.email}</TableCell>
                                        <TableCell>{contact.message}</TableCell>
                                        <TableCell>{showTimeInAgoFormat(contact.createdAt)}</TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={contact.status}
                                                onChange={() => handleCheckboxChange(contact._id, contact.status)}
                                            />
                                        </TableCell> {/* Checkbox to mark as reviewed */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    );
}

export default ContactUsForm;
