/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios'
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import * as XLSX from 'xlsx'
import React, { useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { Card, Table, TableRow, TableBody, TableCell, TableHead } from '@mui/material'
import { BASE_URL } from '../../Api/constants'
import DrawerNav from '../Navigation/DrawerNav'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function AddQuestionWithExcel() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const examName = query.get('name')
    const [excelData, setExcelData] = useState([])
    const [passages, setPassages] = useState([])
    const [loading, setLoading] = useState(false)
    const [successCount, setSuccessCount] = useState(0)
    const [errorCount, setErrorCount] = useState(0)
    const [showResult, setShowResult] = useState(false)
    const [newUsersCount, setNewUsersCount] = useState(0)
    const [existingUsersCount, setExistingUsersCount] = useState(0)

    const handleFileUpload = (e) => {
        const reader = new FileReader()
        reader.readAsBinaryString(e.target.files[0])
        reader.onload = (ee) => {
            const data = ee.target.result
            const workbook = XLSX.read(data, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const parsedData = XLSX.utils.sheet_to_json(sheet)
            setExcelData(parsedData)
        }
    }

    const addBulkData = async (body) => {
        try {
            console.log('Body : ', body)
            const response = await axios.post(`${BASE_URL}/api/v2/questions`, body)
            console.log('Question added successfully:', response.data)
            if (response.data.status === 200) {
                setShowResult(true)
                navigate(-1)
            }
        } catch (err) {
            setErrorCount(errorCount + 1)
        }
    }

    const addQuestionsHandler = async () => {
        try {
            setLoading(true)
            const newRequestBody = []
            for (const questionData of excelData) {
                const requestBody = {
                    type: 'multiple_choice',
                    question: questionData['Question'],
                    title: questionData['Title'],
                    options: [questionData['OptionA'], questionData['OptionB'], questionData['OptionC'], questionData['OptionD'], questionData['OptionE'], questionData['OptionF']],
                    correctOption: questionData['CorrectOption'],
                    explanation: questionData['Explanation'],
                    imageURL: questionData['ImageURL'],
                    answerImageURL: questionData['AnswerImageURL']
                }
                newRequestBody.push(requestBody)
            }
            console.log('Request Body : ', newRequestBody)
            addBulkData({ questions: newRequestBody, examID: id })
            setExcelData([])
        } catch (error) {
            console.error('Error adding question:', error)
        } finally {
            console.log('Here ::: ')
            setLoading(false)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ maxWidth: '100%' }}>
                    <h1 className='text-4xl font-bold my-4'>Add Excel Questions {examName}</h1>
                    {excelData.length === 0 && <input type='file' accept='.xlsx, .xls' onChange={handleFileUpload} />}
                    {excelData.length > 0 && (
                        <LoadingButton size='medium' type='submit' variant='contained' color='success' sx={{ my: 2 }} onClick={addQuestionsHandler} loading={loading}>
                            Add Questions
                        </LoadingButton>
                    )}
                    {showResult && (
                        <Card sx={{ p: 4, mt: 10, textAlign: 'center', backgroundColor: '#bd773d', color: 'white' }}>
                            <Typography variant='h4'>Users added successfully</Typography>
                            <Typography variant='body1'>New users - {newUsersCount}</Typography>
                            <Typography variant='body1'>Existing users - {existingUsersCount}</Typography>
                        </Card>
                    )}
                    {excelData.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {Object.keys(excelData[0]).map((key) => (
                                        <TableCell key={key}>
                                            <Typography variant='subtitle1'>{key}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {excelData.map((row, index) => (
                                    <TableRow key={index}>
                                        {Object.values(row).map((value, index2) => (
                                            <TableCell key={index2}>
                                                <Typography variant='body1'>{value}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
