import { Box } from '@mui/material'
import React from 'react'
import DrawerNav from '../Components/Navigation/DrawerNav'

function MainLayout({ children }) {
    return (
        <Box sx={{ display: "flex" }}>
            <DrawerNav />
            {children}
        </Box>
    )
}

export default MainLayout