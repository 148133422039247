import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { BASE_URL } from '../../Api/constants';
import DrawerNav from '../Navigation/DrawerNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditResource() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log('location.state: ', location.state)
    const { quiz, action, type } = location.state;

    const [name, setName] = useState(quiz.name || '');
    const [description, setDescription] = useState(quiz.description || '');
    const [imageURL, setImageURL] = useState(quiz.imageURL || '');
    const [quizType, setQuizType] = useState(quiz.type || '');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleConfirmOpen = () => {
        setOpenConfirmDialog(true);
    };

    const handleConfirmClose = () => {
        setOpenConfirmDialog(false);
    };

    const handleSaveChanges = async () => {
        handleConfirmClose();
        try {
            const response = await fetch(`${BASE_URL}/api/v1/${type}/${quiz._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    description,
                    imageURL,
                    type: quizType
                })
            });
            if (response.ok) {
                toast.success('Resource modified successfully', { position: 'bottom-left' });
                navigate(-1);
            } else {
                throw new Error('Failed to modify resource');
            }
        } catch (error) {
            toast.error(error.message, { position: 'bottom-left' });
        }
    };

    const handleDeleteTopic = async () => {
        handleConfirmClose();
        try {
            const response = await fetch(`${BASE_URL}/api/v1/${type}/${quiz._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                toast.success('Resource deleted successfully', { position: 'bottom-left' });
                navigate(-1);
            } else {
                throw new Error('Failed to delete resource');
            }
        } catch (error) {
            toast.error(error.message, { position: 'bottom-left' });
        }
    };

    const getOriginalText = (str) => {
        if (str === 'quiz') {
            return 'Quiz';
        } else if (str === 'subject') {
            return 'Subject';
        }
        return str;
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <Box p={2}>
                        <Typography variant='h4' sx={{ marginY: 2 }}>
                            {action === 'edit' ? `Edit ${getOriginalText(type)}` : `Delete ${getOriginalText(type)}`}
                        </Typography>
                        {action === 'edit' && (
                            <>
                                <TextField label='Name' value={name} onChange={(event) => setName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                                <TextField label='Description' value={description} onChange={(event) => setDescription(event.target.value)} fullWidth variant='outlined' margin='normal' />
                                <TextField label='Image URL' value={imageURL} onChange={(event) => setImageURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                                <FormControl fullWidth variant="outlined" margin='normal'>
                                    <InputLabel id="quiz-type-label">Quiz Type</InputLabel>
                                    <Select
                                        labelId="quiz-type-label"
                                        value={quizType}
                                        onChange={(event) => setQuizType(event.target.value)}
                                        label="Quiz Type"
                                    >
                                        <MenuItem value="practice">Practice</MenuItem>
                                        <MenuItem value="course">Course</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleConfirmOpen}>
                                    Save Changes
                                </Button>
                            </>
                        )}
                        {action === 'delete' && (
                            <>
                                <TextField label='Name' disabled value={name} fullWidth variant='outlined' margin='normal' />
                                <Button sx={{ mt: 2 }} variant='contained' color='error' onClick={handleConfirmOpen}>
                                    Delete
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Dialog open={openConfirmDialog} onClose={handleConfirmClose}>
                <DialogTitle>Confirm {action === 'edit' ? 'Save' : 'Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {action === 'edit' ? 'save the changes' : 'delete this resource'}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={action === 'edit' ? handleSaveChanges : handleDeleteTopic} color='primary'>
                        {action === 'edit' ? 'Save' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </>
    );
}
